//Vendor

// import './src/autosize'
// import './src/input-mask'
import './src/dropdown'
import './src/tooltip'
import './src/popover'
// import './src/switch-icon';
import './src/tab'
import './src/toast'
import * as bootstrap from 'bootstrap'

window.bootstrap = bootstrap
